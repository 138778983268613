export default function () {
  const container = document.querySelector('.mega-menu__recent-products');
  const saveProduct = document.querySelector('.save-product');
  let currentLocale;
  if (container) {
    currentLocale = container.dataset.locale.replace('/', '');
  }
  let data = JSON.parse(localStorage.getItem(`recentProducts`)) || [];
  if(saveProduct) {
    if(!data || !data.find((item) => {
      if(!item.data) return false;
      const itemData = JSON.parse(item.data);
      const itemCurrentLocale = itemData.find(el => el.locale === currentLocale);
      return itemCurrentLocale && itemCurrentLocale.name === saveProduct.textContent;
    })) {
      data.unshift({
        url: window.location.href.replace(`/${currentLocale}/`, '/replace-locale/'),
        data: saveProduct.dataset.product
      });
    }
    if(data.length > 5) {
      data.splice(-1, 1)
    }
    localStorage.setItem(`recentProducts`, JSON.stringify(data));
  }
  if (container && data.length) {
    const ul = document.createElement('ul');
    data.forEach((el) => {
      if(el.data) {
        const li = document.createElement('li');
        const a = document.createElement('a');
        const icon = document.createElement('i');
        icon.className = 'icon-red-arrow';
        const elementData = JSON.parse(el.data);
        const currentLocaleElement = elementData.find(elData => elData.locale === currentLocale);
        if(currentLocaleElement.name === undefined) return;
        a.textContent = currentLocaleElement.name;
        a.href = el.url.replace('/replace-locale/', `/${currentLocale}/`);
        li.appendChild(icon);
        li.appendChild(a);
        ul.appendChild(li);
      }
    });
    container.appendChild(ul);
  }
}
